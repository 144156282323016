@keyframes moveClouds {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100vw);
  }
}

.skyBackground {
  position: relative;
  width: 100%;
  height: 100vh; /* You can set this to the height you want */
  background-color: #144ba3; /* Sky Blue */
  overflow: hidden;
  z-index: 0; /* Ensure the cloud layer is behind the content */
}

.cloud {
  position: absolute;
  background: url('cloud_transparent.png') no-repeat;
  background-size: 50%; /* scale down the image by a factor of 4 */
  width: 500px; /* set to 1/4 of the original image width */
  height: 250px; /* set to 1/4 of the original image height */
  animation: moveClouds 35s linear infinite;
  top: 50px;
  z-index: -1; /* Ensure the cloud layer is behind the content */
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)); /* Apply shadow */
}

/* You can add more cloud elements with different sizes and animation durations */
.cloud2 {
  position: absolute;
  background: url('cloud_transparent.png') no-repeat;
  background-size: 50%; /* scale down the image by a factor of 4 */
  width: 500px; /* set to 1/4 of the original image width */
  height: 250px; /* set to 1/4 of the original image height */
  animation: moveClouds 20s linear infinite;
  top: 550px;
  z-index: -1; /* Ensure the cloud layer is behind the content */
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5)); /* Apply shadow */
}

.welcome-text {
  position: absolute;
  top: 20px;
}

.profile-card {
  position: relative;
  width: 200px;
  padding: 20px;
  top: 50px;
  left: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 0px;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.7)); /* Apply shadow */
}

.profile-header {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.profile-info .info-item {
  display: flex;
  align-items: top;
  margin-bottom: 8px;
}

.profile-info .label {
  display: flex;
  flex-direction: column;
  min-width: 60px; /* Adjust the width as needed */
}

.profile-info .content {
  max-width: 150px; /* Adjust the width as needed */

}

.rankbased-content {
  position: relative;
  top: 50px;
  max-width: 500px; /* Adjust the width as needed */

}

.menu-card {
  position: fixed;
  width: 200px;
  padding: 20px;
  top: 50px;
  right: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 20px;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.7)); /* Apply shadow */
}

.fixed {
  position: fixed;
}

.button-fixed {
  z-index: 999;
  position: fixed;
}
