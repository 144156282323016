.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container input {
  margin-bottom: 10px;
}

.login-container button {
  cursor: pointer;
}

